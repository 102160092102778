<template>
  <router-link :to="{name: 'market'}" v-if="user && user.profile.gender !== 'female'" class="header-credits">
    <router-link :to="{name: 'market'}" class="market-link">
      <div class="diamond">
        <b-img :src="iconDiamond1" width="20" />
      </div>
      <div class="market-link-text">
        {{ $t('Market') }}
      </div>
    </router-link>
    <router-link :to="{name: 'market'}">
      <div class="diamond-count d-xl-flex d-lg-flex d-md-flex d-sm-none">
        <div class="diamond">
          <b-img :src="iconDiamond1" width="20" />
        </div>
        <div class="diamond-count-text">
          <span class="remaining">{{ $t('Balance') }}</span>
          <div>
            {{ credits }} {{ $t('Diamonds') }}
          </div>
        </div>
      </div>
    </router-link>
  </router-link>
</template>

<script>
import {
  BImg
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { throwDefaultError } from "@/helpers/helpers";

export default {
  name: 'header-credits',
  components: {
    BImg,
  },
  data() {
    return {
      iconDiamond1: require('@/assets/images/icons/diamonds-1.svg'),
      loading: false,
      messageCount: 0,
      messageTimeLeft: 0,
      user: null,
    }
  },
  created() {
    this.getMe()
  },
  computed: {
    credits() {
      return this.$store.getters['auth/credits']
    }
  },
  methods: {
    getMe() {
      this.loading = true
      useJwt.getMe()
          .then(response => {
            this.user = response.data.body
            this.$store.dispatch('auth/updateUser', this.user)

            console.log('user', this.user)
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => { this.loading = false })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
