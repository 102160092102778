export default [
    // {
    //     title: 'Discover',
    //     route: 'discover',
    //     icon: 'GlobeIcon',
    // },
    {
        title: 'New Users',
        route: 'new-users',
        icon: 'ZapIcon',
    },
    {
        title: 'Popular Users',
        route: 'popular-users',
        icon: 'StarIcon',
    },
    {
        title: 'Nearby',
        route: 'near-by',
        icon: 'GitMergeIcon',
    },
    {
        title: 'For You',
        route: 'for-you',
        icon: 'HeartIcon',
    },
    {
        title: 'Online Users',
        route: 'online-users',
        icon: 'WifiIcon',
    },
    {
        title: 'Video Call Users',
        route: 'video-call-users',
        icon: 'VideoIcon',
    },
    {
        title: 'Chat Menu Title',
        route: 'chat',
        icon: 'MessageSquareIcon',
    },
    // {
    //     title: 'Market',
    //     route: 'market',
    //     icon: 'ShoppingCartIcon',
    // },
    {
        title: 'Interaction',
        icon: 'CopyIcon',
        children: [
            { title: 'Users I like', route: 'interaction.likes' },
            { title: 'People who like me', route: 'interaction.liked' },
            { title: 'Blocked', route: 'interaction.blocked' },
        ],
    },
    {
        title: 'Calls',
        icon: 'PhoneCallIcon',
        children: [
            { title: 'With Voice', route: 'calls.voice' },
            { title: 'With Video', route: 'calls.video' },
        ],
    },
    {
        title: 'Gains',
        route: 'gains',
        icon: 'AwardIcon',
    },
    {
        title: 'Settings',
        route: 'settings',
        icon: 'SettingsIcon',
    },
    {
        title: 'Moments',
        route: 'moments',
        icon: 'ActivityIcon',
    },
    // ...dashboard,
    // ...appsAndPages,
    // ...uiElements,
    // ...formAndTable,
    // ...chartsAndMaps,
    // ...others
]
