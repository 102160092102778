<template>
  <b-nav-item-dropdown
      class="dropdown-notification mr-25"
      menu-class="dropdown-menu-media"
      right
  >
    <template #button-content>
      <feather-icon
          :badge="unReadCount ? unReadCount : ''"
          badge-classes="bg-danger"
          class="text-body"
          icon="BellIcon"
          size="21"
      />
    </template>

    <b-overlay :show="loading" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>

      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            {{ $t('Notifications') }}
          </h4>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
          class="scrollable-container media-list scroll-area"
          tagname="li"
      >
        <!-- Account Notification -->
        <b-link
            v-for="notification in notifications"
            :key="notification.uuid"
            class="notification-item"
            :href="convertUrl(notification.redirectUrl)"
        >
          <b-media :class="{unread: !notification.seen}">
            <template #aside>
              <b-img :src="notification.image" height="32" width="32"/>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.messageText }}
              </span>
            </p>
            <small class="notification-text">
              {{ fromNow(notification.createdAt) }}
            </small>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>

      <!-- Cart Footer -->
      <li class="dropdown-menu-footer">
        <span class="mark-read-all" :style="!unReadCount ? 'opacity: 0.7;' : ''"
              @click="unReadCount ? markReadAll() : null" >

        </span>
      </li>
    </b-overlay>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox, BOverlay, BImg,
} from 'bootstrap-vue'
import LoadingSpinner from '@/components/LoadingSpinner'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import useJwt from '@/auth/jwt/useJwt'
import {showNotify, throwDefaultError} from '@/helpers/helpers'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BImg,
    BLink,
    BAvatar,
    BButton,
    BFormCheckbox,
    BOverlay,

    LoadingSpinner,
    VuePerfectScrollbar,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      iconHeart: require('@/assets/images/icons/heart-2.svg'),
      iconChat: require('@/assets/images/icons/chat-2.svg'),
      loading: false,
      notifications: []
    }
  },
  mounted() {
    this.$root.$on('bv::dropdown::show', bvEvent => {
      if (bvEvent.type === 'show' && this.unReadCount > 0) {
        this.markReadAll()
      }
    })
  },
  created() {
    this.getNotifications()
  },
  computed: {
    unReadCount() {
      return this.notifications.filter(n => !n.seen).length
    }
  },
  methods: {
    convertUrl(url){
      const _url = new URL(url);
      return _url.pathname
    },
    fromNow(date) {
      return moment(date).locale(this.$i18n.locale).fromNow()
    },
    getNotifications() {
      this.loading = true
      useJwt.getNotifications()
          .then(response => {
            this.notifications = response.data.body.onSiteNotifications
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    },
    markReadAll() {
      this.loading = true
      useJwt.markAllNotificationsAsRead()
          .then(response => {
            // showNotify(this.$toast, 'Bildirimler okundu olarak işaretlendi', 'success')
            this.getNotifications()
          })
          .catch(error => {
            throwDefaultError(this.$toast, error)
          })
          .finally(() => {
            this.loading = false
          })
    }
  }
}
</script>

<style lang="scss" scoped>
.mark-read-all {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #F25674;
  cursor: pointer;
}
body:not(.dark-layout) {
  .dropdown-notification {
    .notification-item {
      .media.unread {
        background-color: #efefef;
      }
      .media:hover {
        background-color: #efefef;
      }
    }
  }
}
body.dark-layout {
  .dropdown-notification {
    .notification-item {
      .media.unread {
        background-color: #161d31;
      }
    }
  }
}
</style>
