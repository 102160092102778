<template>
  <li class="nav-item nav-search">

    <!-- Icon -->
    <a
        href="javascript:void(0)"
        class="nav-link nav-link-search"
        @click="showSearchBar = true"
    >
      <feather-icon
          icon="SearchIcon"
          size="21"
      />
    </a>

    <!-- Input -->
    <div
        class="search-input"
        :class="{'open': showSearchBar}"
    >
      <div class="search-input-icon">
        <feather-icon v-if="!loading" icon="SearchIcon"/>
        <b-spinner v-else label="Spinning"></b-spinner>
      </div>
      <!-- <input type="text" placeholder="Explore Vuexy...." class="form-control-input"> -->
      <!-- @keyup.esc="escPressed" -->
      <!-- @keyup.enter="suggestionSelected" -->
      <b-form-input
          v-if="showSearchBar"
          v-model="searchQuery"
          :placeholder="$t('Make a user search')"
          autofocus
          autocomplete="off"
          :disabled="loading"
          enterkeyhint="done"
          ref="searchInput"
          @keyup.esc="showSearchBar = false"
          @keyup.enter="search"
      />
      <div
          class="search-input-close"
          @click="showSearchBar = false"
      >
        <feather-icon icon="XIcon"/>
      </div>

      <div v-if="foundUsers.length > 0" class="search-list search-list-main scroll-area overflow-hidden show">
        <b-row v-for="user in foundUsers" @click="goToUserShow(user.uuid)" :key="user.uuid" class="mb-2 cursor-pointer">
          <b-col class="px-3" sm="12">
            <b-avatar
                size="40"
                :src="user.image"
                variant="light-primary"
           />
            <span class="ml-1">
              {{ user.userName }}
            </span>
          </b-col>
        </b-row>
      </div>

      <!-- Suggestions List -->
      <div v-if="false" class="search-list search-list-main scroll-area overflow-hidden show">
        <b-row v-if="_filter" class="user-filters mb-4">
          <b-col class="filter-column" md="4" sm="12">
            <div class="filter-field">
              <p class="filter-field-title mb-1">
                {{ $t('Looking for Gender') }}
              </p>
              <b-row>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'both'">
                  <b-img thumbnail rounded="circle" :src="imageGenderBoth" :alt="$t('Both')"
                         :class="{active: filter.gender === 'both'}"/>
                  <br>
                  {{ $t('Both') }}
                </b-col>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'female'">
                  <b-img thumbnail rounded="circle" :src="imageGenderFemale" :alt="$t('Female')"
                         :class="{active: filter.gender === 'female'}"/>
                  <br>
                  {{ $t('Female') }}
                </b-col>
                <b-col class="text-center cursor-pointer" sm="4" cols="4" @click="filter.gender = 'male'">
                  <b-img thumbnail rounded="circle" :src="imageGenderMale" :alt="$t('Male')"
                         :class="{active: filter.gender === 'male'}"/>
                  <br>
                  {{ $t('Male') }}
                </b-col>
              </b-row>
            </div>
            <div class="vr"></div>
          </b-col>
          <b-col class="filter-column" md="4" sm="12">
            <div class="filter-field">
              <p class="filter-field-title mb-1">
                {{ $t('Age Range') }}
              </p>
              <b-row>
                <b-col>
                  <vue-slider
                      v-model="filter.ageRange"
                      v-bind="rangeOptions"
                  />
                  <p class="age-range-text mt-1">
                    {{ filter.ageRange.join(' - ') }}
                  </p>
                </b-col>
              </b-row>
            </div>
            <div class="vr"></div>
          </b-col>
          <b-col class="filter-column" md="4" sm="12">
            <div class="filter-field">
              <p class="filter-field-title">
                {{ $t('Country') }}
              </p>
              <b-row>
                <b-col>
                  <v-select
                      v-model="filter.country"
                      :clearable="false"
                      :filter="countrySearch"
                      :reduce="country => country.code"
                      :options="countries"
                  >
                    <template v-slot:option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                    <template v-slot:selected-option="option">
                    <span class="mr-1">
                      {{ option.emoji }}
                    </span>
                      {{ $t(option.name) }}
                    </template>
                  </v-select>
                </b-col>
              </b-row>
            </div>
          </b-col>
          <b-col sm="12" class="text-right">
            <b-button variant="primary" size="sm" @click="search">
              {{ $t('Search') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </li>
</template>

<script>
import {
  BAvatar, BButton, BCol, BFormInput, BImg, BLink, BRow, BSpinner,
} from 'bootstrap-vue'
import {computed, ref, watch} from '@vue/composition-api'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import router from '@/router'
import store from '@/store'
import vSelect from 'vue-select'
import countries from '@/data/countries.json'
import _ from "lodash";
import VueSlider from 'vue-slider-component'
import useJwt from '@/auth/jwt/useJwt'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { useRouter } from '@core/utils/utils'

export default {
  components: {
    BAvatar,
    BButton,
    BCol,
    BFormInput,
    BImg,
    BLink,
    BRow,
    BSpinner,

    vSelect,
    VuePerfectScrollbar,
    VueSlider,
  },
  setup() {
    const count = ref(0)
    const filter = ref(null)
    const filterUpdating = ref(false)
    const foundUsers = ref([])
    const loading = ref(false)
    const searchInput = ref(null)
    const searchQuery = ref('')
    const searchTimeout = ref(null)
    const searchTimeoutTime = ref(1000)

    const { t } = useI18nUtils()

    const $route = useRouter().route

    const _filter = computed({
      get() {
        filterUpdating.value = true
        filter.value = store.getters['search/initialFilter']
        setTimeout(() => {
          filterUpdating.value = false
        }, 100)
        return store.getters['search/initialFilter']
      },
    })

    const showSearchBar = computed({
      get() {
        return store.getters['app/searchBar']
      },
      set(value) {
        store.dispatch('app/setSearchBar', value)
      },
    })

    watch(() => _.cloneDeep(filter.value), val => {
      if (val && !filterUpdating.value) {
        // store.dispatch('app/setSearchFilter', {
        //   ...val,
        //   ageFrom: val.ageRange[0],
        //   ageTo: val.ageRange[1],
        // })
      }
    })

    console.log('$route', $route.value.name)
    watch(() => $route.value.name, (val, oldValue) => {
      if (val !== oldValue) {
        resetSearchQuery()
        showSearchBar.value = false
      }
    })

    watch(() => showSearchBar.value, val => {
      if (!val) {
        filter.value = store.getters['search/initialFilter']
        resetSearchQuery()
      }
    })

    watch(() => searchQuery.value, (val, oldValue) => {
      if (val !== '' && val !== oldValue && val.length > 2) {
        if (searchTimeout.value) {
          clearTimeout(searchTimeout.value)
        }

        searchTimeout.value = setTimeout(() => {
          // search()
          handleAutoComplete()
        }, searchTimeoutTime.value)
      }
    })

    const handleAutoComplete = () => {
      foundUsers.value = []
      loading.value = true
      useJwt.usersListAutoComplete({userName: searchQuery.value})
          .then(response => {
            foundUsers.value = response.data.body
            setTimeout(() => {
              searchInput.value.focus()
            }, 33)
          })
          .catch(error => {
            console.log('error', error)
          })
          .finally(() => {
            loading.value = false
          })
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }

    // watch(searchQuery, val => {
    //   store.commit('app/TOGGLE_OVERLAY', Boolean(val))
    // })

    const resetSearchQuery = () => {
      searchQuery.value = ''
      foundUsers.value = []
      setTimeout(() => {
        if (searchInput.value)
          searchInput.value.focus()
      }, 33)

      filter.value = {
        ..._filter.value,
      }
    }

    const search = () => {
      clearTimeout(searchTimeout.value)

      count.value += 1
      router.push({
        name: 'search-users',
        params: {filter: filter.value},
        query: {userName: searchQuery.value, c: count.value}
      })

      resetSearchQuery()
      // resetSearchQuery()

      // if (searchQuery.value !== '') {
      //   count.value += 1
      //   router.push({
      //     name: 'search-users',
      //     // params: {filter: filter.value},
      //     query: {userName: searchQuery.value, c: count.value}
      //   })
      // }

      // useJwt.updateSearchCriteria({
      //   ageFrom: filter.value.ageRange[0],
      //   ageTo: filter.value.ageRange[1],
      //   country: filter.value.country,
      //   gender: filter.value.gender,
      // })
      //     .then(response => {
      //       // showSearchBar.value = false
      //       count.value += 1
      //       router.push({name: 'search-users', params: {filter: filter.value}, query: {userName: searchQuery.value, c: count.value}})
      //     })
      //     .finally(() => {
      //       loading.value = false
      //     })
    }

    // Filters
    filter.value = _.cloneDeep(store.getters['search/initialFilter'])  // get filter from store

    const countrySearch = (options, search) => {
      return options.filter(country => {
        return country.name.toLowerCase().includes(search.toLowerCase())
            || country.code.toLowerCase().includes(search.toLowerCase())
      })
    }

    const rangeOptions = {
      min: 18,
      max: 100,
      enableCross: false,
      dotOptions: {
        style: {
          backgroundColor: '#F9447A',
          border: '2px solid #fff',
          boxShadow: '0px 0px 14px rgba(0, 0, 0, 0.35)'
        }
      },
    }

    const goToUserShow = (uuid) => {
      showSearchBar.value = false

      router.push({name: 'users.show', params: {id: uuid}})
    }

    return {
      countries: _.sortBy(countries, 'name').map(c => ({ ...c, name: t(c.name) })),
      countrySearch,
      filter,
      _filter,
      foundUsers,
      goToUserShow,
      imageGenderBoth: require('@/assets/images/genders/both.png'),
      imageGenderFemale: require('@/assets/images/genders/female.png'),
      imageGenderMale: require('@/assets/images/genders/male.png'),
      loading,
      perfectScrollbarSettings,
      rangeOptions,
      resetSearchQuery,
      search,
      searchInput,
      searchQuery,
      showSearchBar,
    }
  },
}
</script>

<style lang="scss">
/* Set the theme color of the component */
$themeColor: #F9447A;

/* import theme style */
@import '~vue-slider-component/lib/theme/default.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

p {
  margin: 0;
}


.user-filters {
  padding: 24px;
}

/* .app-auto-suggest {
  position: relative;
}

.auto-suggest-suggestions-list {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 6px;
  position: absolute;
  top: calc(100% + 1rem);
  background-color: white;
  width: 100%;
} */

.suggestion-group-title {
  font-weight: 500;
  padding: .75rem 1rem .25rem;
}

.suggestion-group-suggestion {
  padding: .75rem 1rem
}

.suggestion-current-selected {
  background-color: $body-bg;

  .dark-layout & {
    background-color: $theme-dark-body-bg;
  }
}
</style>
