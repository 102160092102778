export default [
    {
        title: 'Discover',
        route: 'discover',
        icon: 'GlobeIcon',
    },
    {
        title: 'New Users',
        route: 'new-users',
        icon: 'CoffeeIcon',
    },
    {
        title: 'Online Users',
        route: 'online-users',
        icon: 'ZapIcon',
    },
    {
        title: 'Video Call Users',
        route: 'video-call-users',
        icon: 'VideoIcon',
    },
    {
        title: 'Chat Menu Title',
        route: 'chat',
        icon: 'MessageSquareIcon',
    },
    {
        title: 'Market',
        route: 'market',
        icon: 'ShoppingCartIcon',
    },
    {
        title: 'Interaction',
        route: 'interaction',
        icon: 'CopyIcon',
    },
    {
        title: 'Calls',
        route: 'calls',
        icon: 'PhoneCallIcon',
    },
    {
        title: 'Gains',
        route: 'gains',
        icon: 'AwardIcon',
    },
    {
        title: 'Settings',
        route: 'settings',
        icon: 'SettingsIcon',
    },
    // ...dashboard,
    // ...appsAndPages,
    // ...uiElements,
    // ...formAndTable,
    // ...chartsAndMaps,
    // ...others
]
